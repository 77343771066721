import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NavBar } from "./components/NavBar";
import { Footer } from "./components/Footer";
import {Delicious} from './components/Delicious';
import {Wheather} from './components/Delicious';
import { Home } from './components/Home';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav } from 'react-bootstrap';

 function App() {

  return (
      <div className="App">
        <NavBar/>
        <Router> 
          <Routes>      
          <Route path="/"
          element={<Home/>}/>
          <Route path="/Delicious" element = {<Delicious/>}/>
          </Routes>
        </Router>
        <Footer/>
      </div>
  );
};

export default App;

// import logo from './logo.svg';
// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { NavBar } from "./components/NavBar";
// import { Banner } from "./components/Banner";
// import { Skills } from "./components/Skills";
// import { Projects } from "./components/Projects";
// import { Contact } from "./components/Contact";
// import { Footer } from "./components/Footer";

// function App() {
//   return (
//     <div className="App">
//       <NavBar />
//       <Banner />
//       <Skills />
//       <Projects />
//       <Contact />
//       <Footer />
//     </div>
//   );
// }

// export default App;
