import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/mail.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom'; 
import {
  BrowserRouter as Router
} from "react-router-dom";
export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const isHomePage = () => window.location.pathname === '/';

  const handleUpdateActiveLink = (value) => {
    setActiveLink(value);

  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand id="logo" href="/">
            <h1>JP</h1>
            {/* <img src={logo} alt="JP" /> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`ms-auto ${scrolled ? 'scrolled' : ''}`}>
            <Nav.Link href="/" className={(isHomePage() && activeLink === 'home') ? 'active navbar-link' : 'navbar-link'} onClick={() => handleUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href={`${process.env.PUBLIC_URL}/#skills`} className={(activeLink === 'skills') ? 'active navbar-link' : 'navbar-link'} onClick={() => handleUpdateActiveLink('skills')}>Skills</Nav.Link>
            <Nav.Link href={`${process.env.PUBLIC_URL}/#projects`} className={(activeLink === 'projects') ? 'active navbar-link' : 'navbar-link'} onClick={() => handleUpdateActiveLink('projects')}>Projects</Nav.Link>
          </Nav>

            {/* <Nav className="ms-auto">
              <Nav.Link href="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="/#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
              <Nav.Link href="/#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
            </Nav> */}
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/john-pesantez-a678b0235/"><img src={navIcon1} alt="" /></a>
                <a href="mailto:pesjohnmore@gmail.com"><img src={navIcon2} alt="" /></a>
              </div>
              <HashLink to='/#connect'>
                <button className="vvd"><span>Let’s Connect</span></button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
