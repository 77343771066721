// Delicious.js
import React from 'react';
import videoSource1 from '../assets/videos/Diet_Change_Rec.mp4';
import videoSource2 from '../assets/videos/Ingredients_search_Rec.mp4';
import videoSource3 from '../assets/videos/Recipe_Details_Rec.mp4';
import LogojImg from "../assets/img/Delicious-Icon.png";

import { Container, Row, Col } from 'react-bootstrap';

export const Delicious = () => {
  return (
    <section className="delicious-container">
    <Container>
      <br/>
       <div className="logo">
       <img id="delicious-logo" src={LogojImg} alt="" />
      </div>
      <section className="delicious-description">
        <h2 className='delicious-title'>Delicious - Your Ultimate Recipe Companion</h2>
        <p>
          Welcome to Delicious, your go-to app for discovering and exploring delightful recipes!
          Whether you're a seasoned chef or a cooking novice, Delicious has something for everyone.
          Explore a wide range of recipes, watch video tutorials, and enhance your culinary skills.
        </p>
        <h3>Abstract</h3>
        <div class="horizontal-line"></div>
        <p>
        The project aimed to develop a recipe app that improves the user experience for individuals with dietary requirements and limited time for recipe ideas. 
        - App developed using SwiftUI, MySQL, and PHP.
        - Filters available for allergies, intolerances, and preferences. 
        - Ingredient-based recipe selection feature included. 
        - Usability testing conducted. 
        - Future objectives: Implement image recognition and enhance user experience. 
        - Aligns with research emphasising the importance of tailored recipe suggestions for dietary needs. 
        </p>
      </section>

      {/* Four Divs Splitting the Screen */}
      <div className="section2">
      <section className="four-divs">
        <Row>
          <Col md={6}>
            <div className="div1">
              <h2>Introduction</h2>
              <div class="horizontal-line"></div>
              <p>
                The modern lifestyle has led to a high demand for mobile apps that simplify daily activities, including food and cooking.
                <ul>
                  <li>
                  Recipe apps have become essential for individuals with specific dietary requirements, busy schedules, or limited ingredients.
                  </li>
                  <li>
                  Mobile apps offer convenience and accessibility compared to traditional recipe books, which often come at a cost.
                  </li>
                  <li>
                  Apps and websites provide not only text and photos but also audio and video instructions, benefiting those with visual impairments.
                  </li>
                  <li>
                  This project aims to develop a recipe app catering to users with specific dietary needs.
                  </li>
                  <li>
                  The motivation for this research is the increasing prevalence of dietary restrictions.
                  </li>
                  <li>
                  The project aims to provide an efficient and user-friendly recipe selection process for individuals with nutritional requirements.
                  </li> 
                </ul>
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="div2">
              <h2>Methodology</h2>
              <div class="horizontal-line"></div>
              <p>
                An Iterative and Adaptive Development approach was chosen for the project due to its flexibility and responsiveness. 
                <ul>
                  <li>
                    I learned programming languages on the go and refined code as issues arose.
                  </li>
                  <li>
                  SwiftUI was chosen for iOS app development due to its ease of use and experimentation capabilities. 
                  </li>
                  <li>
                  IONOS was selected as a web hosting provider for reliable login and registration system with MYSQL database storage.
                  </li>
                  <li>
                  phpMyAdmin is used for efficient database management.
                  </li>
                  <li>
                  PHP was chosen for the seamless integration of data into SwiftUI app.
                  </li>
                  <li>
                  Recipes retrieved from API in JSON format instead of using MySQL database.
                  </li>
                  <li>
                  Client-side data storage is chosen to meet specific project requirements.
                  </li>
                  <li>
                  Iterative and adaptive development approach with suitable programming languages and technologies.
                  </li>
                </ul>
                </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="div3">
              <h2>Results</h2>
              <div class="horizontal-line"></div>
              <p>
              As a developer, I took it upon myself to create a recipe app that caters to the needs of all users. 
              – Simple and user-friendly login process for an effortless sign-in experience. 
              – Robust ingredient-based search function for easy recipe discovery. 
              – Personalised recipe lists based on dietary needs. 
              – Detailed recipe view with video support for an immersive cooking experience.
              – Intuitive and easy-to-navigate design for quick familiarisation with the interface. 
              – Video support in recipe view enhances understanding and enjoyment of the cooking process. 
              – Continuous collection and analysis of user feedback for ongoing improvement. 
              – User-centred design principles ensure a personalised and enjoyable user experience.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="div4">
              <h2>Conclusion</h2>
              <div class="horizontal-line"></div>
              <p>
              The recipe app caters to all users with its user-friendly login process, robust ingredient-based search, and personalised recipe lists based on dietary requirements. 
              Detailed recipe views with video support enhance the cooking experience. 
              The app's success lies in its intuitive design, easy navigation, personalised search results, and customizable recipe lists. 
              Continuous user feedback drives ongoing improvements for a personalised and enjoyable experience.
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </div>


      <section className="videos-section">
        <h2>App Demo Videos</h2>
        <div class="horizontal-line"></div>
        <Row className="video-list">
          <Col md={6} lg={4}>
            <div className="video-card">
              <iframe
                width="360"
                height="640"                src={videoSource1}
                type="video/mp4"
                title="Delicious iOS App Demo 1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p>Discover a world of recipes tailored to your dietary preferences. Watch how the app instantly adapts, offering a variety of delicious options based on your chosen dietary requirements.</p>
            </div>
            
          </Col>
          <Col>
          <div className="video-card">
              <iframe
                width="360"
                height="640"
                src={videoSource2}
                type="video/mp4"
                title="Delicious iOS App Demo 2"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p>See the app in action as it transforms your list of ingredients into curated recipe suggestions. Effortlessly find recipes that match what you have, simplifying your cooking experience.</p>
            </div>
            </Col>
            <Col>
          <div className="video-card">
              <iframe
                width="360"
                height="640"                src={videoSource3}
                type="video/mp4"
                title="Delicious iOS App Demo 3"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p>Dive into the final screen to experience a detailed recipe overview. Watch a video guide, view required ingredients, and follow step-by-step instructions. Everything you need for a successful cooking adventure in one place.</p>
            </div>
            </Col>
          {/* Add more video cards as needed */}
        </Row>
      </section>
      <br/>
    </Container>
  </section>

  );
}
