import React from "react";
import { Banner } from "./Banner";
import { Contact } from "./Contact";
import { Projects } from "./Projects";
import { Skills } from "./Skills";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
// import '../App.css';
export const Home = () => {
    return(
        <div className="App">   
        <Banner/>
        <Skills/>
        <Projects/>
        <Contact/>
        </div>
 
    );
};
